.gs-dialog {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  align-items: center;
  padding: 40px;

  background-color: #0009;

  .modal-content {
    flex-shrink: 1;
    max-width: 100%;
    width: auto;
    min-width: 20vw;
  }
}