/* Scheme stuff */
#mediascheme {
    border-top: none;
}

#mediascheme .scheme_row {
    margin: 0;
    padding: 10px;
    background: #fafafa;
    border-top: 1px solid #ddd;

    .span2 {
        width: 13.20%; /* ?! */
        margin-left: 1%;
    }
}

.scheme_item {
    width: auto;
    margin-right: 32px;
    -webkit-border-radius: 2px;
    box-sizing: border-box;

    font-size: 12px;
    text-transform: capitalize;
    color: #ddd;color: #555;
    text-shadow: 1px 1px 0px #fff;
}

.scheme_item.sport,
.scheme_item.filler,
.scheme_item.playlist,
.scheme_item.news {
    padding: 5px 0 5px 5px;
    height:60px;
    box-shadow: 1px 1px 4px #555;

    &:hover {
        box-shadow: 1px 1px 4px #000;
    }
}

.scheme_item.sport {
    background-color: #FCF8E3;
}

.scheme_item.playlist {
    background-color: #F2F2F2;
}

.scheme_item.news {
    background-color: #F2DEDE;
}

.scheme_item.filler {
    background-color: #DFF0D8;
    border: dashed 1px rgb(103, 167, 116);
}
