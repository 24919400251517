// Calendar

.open .tz-date-browser {
    background-color: $infoBackground;
    border: 1px solid #ddd;
}

.tz-date-browser {
    border: 1px solid #fff;
      -webkit-border-radius: 4px;
         -moz-border-radius: 4px;
              border-radius: 4px;
    padding: 5px 10px;

    &:hover {
        background-color: $infoBackground;
        border: 1px solid $infoBackground;
    }
}

.tz-date-nav:hover {
      -webkit-border-radius: 4px;
         -moz-border-radius: 4px;
              border-radius: 4px;
    background-color: $infoBackground;
}

.tz-selected-date {
    background-color: $blue;
    color: $white;
    margin: 0 -0.4em 0 -0.1em;
      -webkit-border-radius: 4px;
         -moz-border-radius: 4px;
              border-radius: 4px;
    text-align: center;
    border: #adadad 1px $blue;
}

.rw-calendar thead > tr {
    border-bottom: 1px solid #ccc;
}

.rw-calendar .rw-header {
    padding-bottom: 0;
}

.rw-calendar .rw-header .rw-btn-view {
      -webkit-border-radius: 0;
         -moz-border-radius: 0;
              border-radius: 0;
}

.rw-i-chevron-up,
.rw-i-chevron-down,
.rw-i-chevron-left,
.rw-i-chevron-right,
.rw-i-caret-up,
.rw-i-caret-down,
.rw-i-caret-left,
.rw-i-caret-right,
.rw-i-calendar,
.rw-i-clock-o {
    display: inline-block;
    width: 14px;
    height: 14px;
    line-height: 14px;
    vertical-align: text-top;
    background-image: url("../img/glyphicons-halflings.png");
    background-position: 14px 14px;
    background-repeat: no-repeat;
    margin-top: 1px;
}

.rw-i-chevron-up:before,
.rw-i-chevron-down:before,
.rw-i-chevron-left:before,
.rw-i-chevron-right:before,
.rw-i-caret-up:before,
.rw-i-caret-down:before,
.rw-i-caret-left:before,
.rw-i-caret-right:before,
.rw-i-calendar:before,
.rw-i-clock-o:before {
    content: '' !important;
}

.rw-i-chevron-up, .rw-i-caret-up          { background-position: -288px -120px; }
.rw-i-chevron-down, .rw-i-caret-down        { background-position: -313px -119px; }
.rw-i-chevron-left, .rw-i-caret-left        { background-position: -432px -72px; }
.rw-i-chevron-right, .rw-i-caret-right       { background-position: -456px -72px; }
.rw-i-calendar          { background-position: -192px -120px; }
.rw-i-clock-o           { background-position: -48px  -24px; }

input.rw-select-list-input {
  top: 50% !important;
}

// Temp. until https://github.com/jquense/react-widgets/issues/260 is fixed.
.tz-interval-select .rw-state-selected {
    border: none;
    background-color: transparent;
}

.rw-multiselect.w-100 input {
  width: 100%;
}