.gs-col-layout {
  display: grid;
  grid-template-columns: minmax(50%, 1fr);
}

.gs-col-layout.with-top-bar {
  grid-template-rows: auto 1fr;
}

.gs-col-left {
  grid-column-start: 1;
}

.gs-col-right {
  grid-column-start: 2;
}

.gs-grid-top {
  grid-column: 1/3;
}

@media screen and (min-width: 550px) {
  .gs-col-layout {
    display: grid;
    grid-template-columns: 550px minmax(50%, 1fr);
  }
}

@media screen and (max-width: 1100px) {
  .gs-col-layout .gs-sidebar {
    position: fixed;
  }
}