.timeline {
    background: linear-gradient(0deg, #01689C,#0385C5);

    &.inactive {
        background: grey;
    }

    &.overflowUpper {
        background: linear-gradient(to right, #01689C 0%, #0385C5 90%, #fff 100%);
    }
    &.overflowLower {
        background: linear-gradient(to right, #fff 0%, #01689C 10%, #0385C5 100%);
    }

    &.overflowLower.overflowUpper {
        background: linear-gradient(to right, #fff 0%, #01689C 10%, #0385C5 90%, #fff 100%);
    }
}
