$transition-4: 400ms;
$transition-3: 300ms;
$transition-2: 200ms;
$transition-1: 150ms;

.fade.in {
    opacity: 1;
}

.animateAll {
    transition: all 200ms cubic-bezier(0.7, 0, 0.175, 1) 0s;
}

// ReactCSSTransitionGroup utils

.fadeInOut-enter {
    max-height: 0;
    opacity: 0.01;
    overflow: hidden;
}

.fadeInOut-enter.fadeInOut-enter-active {
    max-height: 100px;
    opacity: 1;
    transition: opacity 500ms cubic-bezier(0.7, 0, 0.175, 1) 0s,
        max-height 500ms cubic-bezier(0.7, 0, 0.175, 1) 0s;
}

.fadeInOut-exit,
.fadeInOut-leave {
    max-height: 100px;
    opacity: 1;
    overflow: hidden;
}

.fadeInOut-exit.fadeInOut-exit-active,
.fadeInOut-leave.fadeInOut-leave-active {
    max-height: 0;
    opacity: 0.01;
    transition: opacity $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s,
        max-height $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s;
}


.simpleFade-enter {
    opacity: 0.01;
}

.simpleFade-enter.simpleFade-enter-active {
    opacity: 1;
    transition: opacity 100ms cubic-bezier(0.7, 0, 0.175, 1) 0s;
}

.simpleFade-exit {
    opacity: 1;
}

.simpleFade-exit.simpleFade-exit-active {
    opacity: 0.01;
    transition: opacity 100ms cubic-bezier(0.7, 0, 0.175, 1) 0s;
}




.faderow-enter {
    opacity: 0.01 !important;
    /*background-color: rgba($green, .5);*/
    max-height: 0;
}

.faderow-enter.faderow-enter-active {
    opacity: 1 !important;
    max-height: 500px;
    /*background-color: rgba($green, 0);*/
    transition:
        opacity $transition-4 ease-in,
        /*background-color 3000ms ease-out,*/
        max-height 1000ms ease-in !important;
}

.faderow-exit {
    opacity: 1 !important;
    max-height: 500px;
    background-color: rgba($gray-500, 0);
    overflow: hidden;
}

.faderow-exit.faderow-exit-active {
    opacity: 0.01 !important;
    max-height: 0;
    background-color: $gray-500;
    transition:
        opacity $transition-4 ease-in,
        background-color $transition-4 ease-out,
        max-height $transition-4 ease-out !important;
}


// Slide left/right

.slideLeft-enter {
    @include translateX(100px);
    opacity: 0.01;
}

.slideLeft-enter.slideLeft-enter-active {
    @include translateX(0);
    opacity: 1;
    transition: transform $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s;
}

.slideLeft-leave,
.slideLeft-exit {
    @include translateX(0);
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
}

.slideLeft-leave.slideLeft-leave-active,
.slideLeft-exit.slideLeft-exit-active {
    @include translateX(-100px);
    opacity: 0.01;
    transition: transform $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s;
}





.slideRight-enter {
    @include translateX(-100px);
    opacity: 0.01;
}

.slideRight-enter.slideRight-enter-active {
    @include translateX(0);
    opacity: 1;
    transition: transform $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s;
}

.slideRight-leave,
.slideRight-exit {
    @include translateX(0);
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
}

.slideRight-leave.slideRight-leave-active,
.slideRight-exit.slideRight-exit-active {
    @include translateX(100px);
    opacity: 0.01;
    transition: transform $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s;
}

// Quick slide

.quickSlideLeft-enter {
    @include translateX(50px);
    opacity: 0.01;
}

.quickSlideLeft-enter.quickSlideLeft-enter-active {
    @include translateX(0);
    opacity: 1;
    transition: transform $transition-3 cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity $transition-3 cubic-bezier(0.7, 0, 0.175, 1) 0s;
}

.quickSlideLeft-leave,
.quickSlideLeft-exit {
    @include translateX(0);
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
}

.quickSlideLeft-leave.quickSlideLeft-leave-active,
.quickSlideLeft-exit.quickSlideLeft-exit-active {
    @include translateX(-50px);
    opacity: 0.01;
    transition: transform $transition-1 cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity $transition-1 cubic-bezier(0.7, 0, 0.175, 1) 0s;
}



.quickSlideRight-enter {
    @include translateX(-50px);
    opacity: 0.01;
}

.quickSlideRight-enter.quickSlideRight-enter-active {
    @include translateX(0);
    opacity: 1;
    transition: transform $transition-3 cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity $transition-3 cubic-bezier(0.7, 0, 0.175, 1) 0s;
}

.quickSlideRight-leave,
.quickSlideRight-exit {
    @include translateX(0);
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
    right: 0;
}

.quickSlideRight-leave.quickSlideRight-leave-active,
.quickSlideRight-exit.quickSlideRight-exit-active {
    @include translateX(50px);
    opacity: 0.01;
    transition: transform $transition-1 cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity $transition-1 cubic-bezier(0.7, 0, 0.175, 1) 0s;
}

//


.slideLeftRight-enter {
    @include translateX(100px);
    opacity: 0.01;
}

.slideLeftRight-enter.slideLeftRight-enter-active {
    @include translateX(0);
    opacity: 1;
    transition: transform 250ms cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity 250ms cubic-bezier(0.7, 0, 0.175, 1) 0s;
}

.slideLeftRight-exit {
    @include translateX(0);
    position: absolute;
    opacity: 1;
    top: 0;
    left: 0;
}

.slideLeftRight-exit.slideLeftRight-exit-active {
    @include translateX(100px);
    opacity: 0.01;
    transition: transform 250ms cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity 250ms cubic-bezier(0.7, 0, 0.175, 1) 0s;
}


// Slide up/down

.slideUpDown-enter {
    @include translateY(200px);
    opacity: 0.01;
}

.slideUpDown-enter.slideUpDown-enter-active {
    @include translateY(0);
    opacity: 1;
    transition: transform $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s;
}

.slideUpDown-leave {
    @include translateY(0);
    opacity: 1;
}

.slideUpDown-leave.slideUpDown-leave-active {
    @include translateY(200px);
    opacity: 0.01;
    transition: transform $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity $transition-4 cubic-bezier(0.7, 0, 0.175, 1) 0s;
}


// Tooltip

.tooltipToggle-enter {
    @include translateY(30px);
    z-index: 10;
    opacity: 0.01;
}

.tooltipToggle-enter.tooltipToggle-enter-active {
    @include translateY(0);
    opacity: 1;
    transition: transform 300ms cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity 300ms cubic-bezier(0.7, 0, 0.175, 1) 0s;
}

.tooltipToggle-leave {
    @include translateY(0);
    z-index: 10;
    opacity: 1;
}

.tooltipToggle-leave.tooltipToggle-leave-active {
    @include translateY(30px);
    opacity: 0.01;
    transition: transform 0ms cubic-bezier(0.7, 0, 0.175, 1) 0s,
                opacity 0ms cubic-bezier(0.7, 0, 0.175, 1) 0s;
}