


.report-view {
	h1,h2,h3,h4,h5 {
		text-transform: capitalize;
	}

	.right {
		float:right;
	}

	.pointer {
		cursor: pointer;
	}

	.center {
		text-align: center;
	}

	.select-label {
		margin: 10px 0 0 0;
	}

	.filter-tag {
		max-width: 95px;
		overflow: hidden;
		display: block;
		float: left;
		text-overflow: ellipsis;
	}

	.w100 {
		width:100%;
	}

	a {
		cursor: pointer;
	}

	.small {
		font-size: 10px;
	}

	.load-text {
		position: absolute;
		width: 100%;
		text-align: center;
		font-size: 20px;

		span {
		    background-color: #8798A9;
		    border-radius: 5px;
		    padding: 10px 30px;
		    color: white;
		}
	}

	.legend {
		display: inline-block;

		.entry {
			margin-right: 10px;
			display: inline-block;
			

			.marker {
				max-width: 200px;
	    		overflow: hidden;
	    		white-space: nowrap;
	    		text-overflow: ellipsis;
				padding: 0 7px;
				color: white;
				text-align: center;
				min-width: 40px;
				border-radius: 3px;
				display: inline-block;
			}
		}
	}

	tbody {
		tr:hover {
			background-color: #ECECEC;
		}

		tr {
			cursor: pointer;
		}
	}

	.stats-table{
		td:nth-child(3){
			border-right: 1px solid black;
		}
		
	}

	.disabled {
		cursor: default;
		color: gray;
		text-decoration: none;
		opacity: 0.6;
	}

	.toggled {
		background: #CFE4E6;
	}

	.toggled:hover {
		background: #C6FAFF;
	}

	.empty {
		background: #E6E6E6;
	}

	.tt-none {
		text-transform: none;
	}

	.filter-input {
		width: 100%;
		padding: 4px 0px;
	}
}