/*
 *  Messages
 */
#messages {
  position: fixed;
  width: 340px;
  margin: 20px auto;
  left: 20px;
  bottom: 0;
  z-index: 100000;
}

#messages .alert:hover {
  opacity: 0.8 !important;
}

#messages .alert {
  opacity: 0.9;

  -webkit-box-shadow: 1px 1px 5px 0px #666;
  -moz-box-shadow: 1px 1px 5px 0px #666;
  box-shadow: 1px 1px 5px 0px #666;

  -moz-transition: opacity 0.2s ease;
  -webkit-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}