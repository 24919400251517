/* Borders: */

.bdr888 {
	border: 1px solid #888888;
}

/* Border radius: */

table.rounded {
	border-collapse: separate;
	border-spacing: 0;

	th:first-child {
		border-radius: 4px 0 0 0;
	}
	th:last-child {
		border-radius: 0 4px 0 0;
	}

	tr:last-child {
		td:first-child {
			border-radius: 0 0 0 4px;
		}
		td:last-child {
			border-radius: 0 0 4px 0;
		}
	}
}

iframe {
	border: none;
}
