// Padding:

.pa2    {padding:2px}
.pa5    {padding:5px}
.pa10   {padding:10px}
.pa15   {padding:15px}

td.pt2, .pt2    {padding-top:2px}
.pt44   {padding-top:44px}
.ios.pt44   {padding-top:0} // fix this

.pb44   {padding-bottom:44px}


// Margins

.mt2        {margin-top:2px}
.mt3        {margin-top:3px}
.mt4        {margin-top:4px}
.mt15       {margin-top:15px}

.mb44       {margin-bottom:44px}

.abs-fill {
	position: absolute;
	top: 0;
	left:0;
	bottom:0;
	right:0;
}
