// dropdown-menu-right only works within navbars apparently.
@keyframes showNav {
    from {
        opacity: 0;
        transform: translate3d(0, -10px, 0);
    }

    to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.dropdown-item {
    border-radius: 0;
}

.dropdown-menu {
    position: static;
    will-change: transform, opacity;

    &.show {
        animation: showNav 200ms ease-in-out both;
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

.dropdown-menu-right {
    left: auto; // Reset the default from `.dropdown-menu`
    right: 0;
}

.dropup > .dropdown-menu {
    bottom: 100%;
    left: 0;
    top: auto;

    &.dropdown-menu-right {
        @extend .dropdown-menu-right;
    }
}

.dropdown-toggle.no-caret::after {
    display: none;
}

.btn {
    cursor: pointer;

    &.btn-lg {
        font-size: $font-size-lg;
    }

    &.btn-sm {
        font-size: calc(#{$font-size-sm}*0.85);
    }

    &.disabled {
        cursor: not-allowed;
    }
}
