/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../media/fonts/roboto-v29-latin-regular.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../media/fonts/roboto-v29-latin-700.woff2') format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}

html {
  font-size: 14px;
}

.text-outline-white {
    font-weight: 900;
    /*-webkit-text-stroke-width: 1px;*/
    /*-webkit-text-stroke-color: #eee;*/
    text-shadow:
       -1px -1px 0 #eee,
        1px -1px 0 #eee,
        -1px 1px 0 #eee,
         1px 1px 0 #eee;
}

.truncate-fade {
  position: relative;

  &:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 8px;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), var(--current-background) 75%);
  }
}