$right-col-width: 550px;
$sidebar-width: 550px;

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

.gs-sidebar {
  position: absolute;
  right: 0;
  top: 0px;
  height: 100%;
  width: $sidebar-width;
  z-index: 1;
  -o-transition: transform 0.3s ease;
  -webkit-transition: transform 0.3s ease;
  -moz-transition: transform 0.3s ease;
  transition: transform 0.3s ease;

  .holder {
    position: relative;
    height: 100%;
    width: auto;
    overflow-y: auto;
  }
}

.blocker {
  transform: translate3d(0,0,0);
  position: fixed;
  background: #000;
  opacity: 0;
  width: 0;
  height: 0;
  -o-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;

  &.blocked {
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
  }
}

.holder {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

/*
 * Layout/grid LEGACY
 */

.lcol{
  background: #ffffff;
}

.vertical-layout {

  .container_column {
    position : absolute;
    top: 0;
    bottom: 0;
  }

  .rcol,
  .rcol_bg {
    right: 0;
    left : 550px;
    // width: 60%;
  }

  .lcol {
    left: 0;
    width: 550px;
  }
}

.custom-layout {

  position: absolute;
  top : 0;
  bottom: 0;
  right: 0;
  left: 150px;

  .container_column {
    position : absolute;
    width: 50%;
    top: 0;
    bottom: 0;
  }

  .rcol,
  .rcol_bg {
    right: 0;
    width: 60%;
  }

  .lcol {
    left: 0;
    width: 40%;
  }
}

.horizontal-layout {
  .container_column {
    position : absolute;
    width: 100%;
    top: 40px;
    bottom: 0;
  }

  .lcol {
    bottom: 50%;
  }

  .rcol,
  .rcol_bg {
    top: 50%;
  }
}

.nav-icon {
    @extend .icon-white;
}
