
/* Backgrounds */


// Gradients/images

.bg_bluestriped {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#D0DCE8), color-stop(50%, #b0b0b0));
	background-image: -moz-linear-gradient(90deg, #b0b0b0 50%, #D0DCE8);
	background: #8899aa url("../images/stripe_light.png");
}

// Shadows

$bshadow: #334455;

.bshadow {
	-moz-box-shadow: 2px 2px 3px $bshadow;
	-webkit-box-shadow: 3px 3px 4px $bshadow;
	box-shadow: 2px 2px 3px $bshadow;
	/* For IE 8 */
	-ms-filter: "progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#aaaaaa')";
	/* For IE 5.5 - 7 */
	filter: progid:DXImageTransform.Microsoft.Shadow(Strength=4, Direction=135, Color='#aaaaaa');
}

:root {
	--current-background: rgba(255, 255, 255, 1);
}

@each $color, $value in $theme-colors {
	.bg-#{$color} {
		--current-background: #{$value};
	}
}
