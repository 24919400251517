/*
 * General styles
 */

 input.error {
 	background-color: $errorBackground;
 }

div#login {
	margin: auto;
	width: 300px;
	margin-top: 100px;
	border: 1px solid gray;

	padding: 20px;
	-moz-border-radius: 5px;
}

ul.messages{
	width: 300px;
	text-align: center;
	margin: auto;
	border: 1px solid gray;
	padding: 5px;
	-moz-border-radius: 5px;
	background-color: yellow;
}

.hidden {
	display:none;
}

.clear{
	clear: both;
}

.pointer {
	cursor: pointer;
}


// Overflowing text
.to1 {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    white-space: nowrap;
}

.overflow-y-auto {
	overflow-y: auto;
}

/*
 * Navigation/topbar
 */

a.brand{
	display:block;
	width: 120px;
    height: 40px;
	background: url("images/inline_logo.png") 1px 7px  no-repeat;
    background-size: contain;
}

a.brand span{
	display: none;
}

.navbar #app_header #main_menu a{
    opacity: 0.7;
}

.navbar #app_header #main_menu .active a {
	opacity: 1;
}

.navbar a {
	cursor: pointer;
	text-transform: capitalize;
}

a.no-cap {
	text-transform: none;
}

/*
 * Popups @TODO: replace with bootstrap modals // Or replace with other convention alltogether, modals are evil!
 */

 // react-modal style overload
 // they dont apply z-index, so any element
 // with a set z index will overlay the modal
 .ReactModal__Overlay {
   z-index: 99;
 }


/*
 * Modules
 */

.rcol .module_head,
#popup .module_head{
	display:block;
	padding:5px;
	padding-bottom:0;
	border-bottom: 1px solid #E5E5E5;
	-moz-border-radius: 4px 4px 0 0;
    border-radius: 4px 4px 0 0;
}

/*
 * Add modules
 */
.edit-module {
    min-width: 285px;
	position: relative;
}

/*
 * List module
 */

.search_field{
	float:right;
}

.table-fixed {
	table-layout: fixed;
}

.table_container > table > thead > tr {
	background: #eeeeee;
	border-top: 1px solid #dddddd;

	th {
		padding-top: 3px;
		padding-bottom: 3px;
		overflow: hidden;
	    text-overflow: ellipsis;
	    -o-text-overflow: ellipsis;
	    white-space: nowrap;
	}
}

.table_container table tr td,
.table_container table tr th {
	vertical-align: middle;
	font-size: 10pt;
	overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

.table_container table tr td.v_top,
.table_container table tr th.v_top,
table.v_top > tbody > tr > td,
table.v_top > thead > tr > th {
	vertical-align: top;
}

.table-condensed th{
	padding: 2px 5px;
}


.video-spinner{
    position: absolute;
    background: #eee;
    padding: 7px;
    margin: 7px;
    border-radius: 5px;
    font-size: 10px;
    color: #444;
    border: 1px solid darkgray;
    display: none;
}



.table_container td.td-popover{
  overflow: visible;
}

tr.subsequent td{
    border-top: 2px solid white;
}

.table th, .btn, .cap {
    text-transform: capitalize;
}

.nowrap {
	white-space: nowrap;
}

.bold {
	font-weight: bold;
}

table {
	transition: background-color 0.05s ease-out;
}

.valign-sub {
	vertical-align: sub;
}

.bbox {
	box-sizing: border-box;
}


.showall td{
	font-size: 10px;
	cursor: pointer;
	font-weight: bold;
}
