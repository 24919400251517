.scheme-preview {
  .lcol {
    box-sizing: border-box;
  }

  .rcol {
    overflow-y: auto;
  }

  .map {
    height: 200px;
  }

  .player {
    height: 100px;
  }

  .right-panel {
    flex: 1;
    position: relative;
  }

  .media-wrapper {
    width: 100%;
    height: 100%;
    background: black;
    position: relative;
    border-radius: 2px;
    overflow: hidden;

    .gone {
      display: none;
    }

    .hidden {
      opacity: 0.05;
    }

    img,
    div,
    video,
    iframe {
      border-radius: 2px;
      position: absolute;
      top: 50%;
      left: 50%;
      transition: opacity 0.1s ease-in-out;
    }
  }

  .well {
    li,
    tr {
      background-color: white;
      padding: 5px;
      box-sizing: border-box;
      transition: all 0.05s ease-out;
      border-right: 0px solid #1e8c08;
      overflow: hidden;
      text-overflow: ellipsis;
      border-top: 1px solid #d4d4d4;
    }

    li:last-child {
      border-radius: 0 0 3px 3px;
    }

    li:only-child {
      border-radius: 3px;
    }

    li:first-child {
      border-radius: 3px 3px 0 0;
      border-top: none;
    }

    ul {
      list-style: none;
      margin: 5px;
      border-radius: 3px;
      border: 1px solid #d4d4d4;
    }
  }

  .scheme-list {
    .inactive {
      background-color: #ebebeb;
      color: grey;
    }

    .current {
      background-color: #c9eec2;
      font-weight: bold;
    }
  }

  .history-list {
    max-height: 300px;
    overflow-y: auto;
  }

  .fullscreen-player {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10000;
  }

  .speed-picker {
    input {
      margin-left: -15px;
    }
  }

  .media-wrapper {
    background: #000;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }

  .map-container {
    z-index: 1;
    position: relative;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  }

  .controls-container {
    background: #fff;
    border-radius: 3px;
    position: absolute;
    z-index: 10;
    bottom: 10px;
    left: 10px;
    width: calc(100% - 20px);
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .controls-button {
    cursor: pointer;
    padding: 0 10px;
  }

  .history-item {
    margin-top: 10px;
    min-height: 40px;
    transition: all 0.2s;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;

    &:last-child {
      border-bottom: none;
    }
  }

  .history-item-image-wrapper {
    width: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .history-item-name {
    font-size: 16px;
    margin-left: 10px;
  }

  .history-item-playlist {
    margin-top: 4px;
    font-size: 13px;
    color: #888;
  }
}
