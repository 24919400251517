@mixin applyTransition($attr: all, $dur: 0.2s) {
    transition: $attr $dur cubic-bezier(0.7, 0, 0.175, 1) 0s;
}

@mixin translate($x: 0, $y: 0) {
    -webkit-transform: translate($x, $y);
       -moz-transform: translate($x, $y);
        -ms-transform: translate($x, $y);
         -o-transform: translate($x, $y);
            transform: translate($x, $y);
}

@mixin translateX($x: 0) {
    -webkit-transform: translateX($x);
       -moz-transform: translateX($x);
        -ms-transform: translateX($x);
         -o-transform: translateX($x);
            transform: translateX($x);
}


@mixin translateY($y: 0) {
    -webkit-transform: translateY($y);
       -moz-transform: translateY($y);
        -ms-transform: translateY($y);
         -o-transform: translateY($y);
            transform: translateY($y);
}
