
table td,
table th {
  box-sizing: content-box;
}

.table {
  thead th {
    border-top: none;
  }

  &:not(.table-borderless) {
    thead th {
      border-bottom: $table-border-width solid $table-border-color;
    }

    tbody + tbody {
      border-top: $table-border-width solid $table-border-color;
    }
  }

}


/* Table Outline Verison*/
.table-outline {
  border: 1px solid $tableBorder;
  border-collapse: separate; // Done so we can round those corners!
  *border-collapse: collapsed; // IE7 can't round corners anyway
  border-left: 1px solid $tableBorder;
  -webkit-border-radius: 4px;
     -moz-border-radius: 4px;
          border-radius: 4px;

  // Prevent a double border
  caption + thead tr:first-child th,
  caption + tbody tr:first-child th,
  caption + tbody tr:first-child td,
  colgroup + thead tr:first-child th,
  colgroup + tbody tr:first-child th,
  colgroup + tbody tr:first-child td,
  thead:first-child tr:first-child th,
  tbody:first-child tr:first-child th,
  tbody:first-child tr:first-child td {
    border-top: 0;
  }
  // For first th or td in the first row in the first thead or tbody
  thead:first-child tr:first-child th:first-child,
  tbody:first-child tr:first-child td:first-child {
    -webkit-border-top-left-radius: 4px;
            border-top-left-radius: 4px;
        -moz-border-radius-topleft: 4px;
  }
  thead:first-child tr:first-child th:last-child,
  tbody:first-child tr:first-child td:last-child {
    -webkit-border-top-right-radius: 4px;
            border-top-right-radius: 4px;
        -moz-border-radius-topright: 4px;
  }
  // For first th or td in the first row in the first thead or tbody
  thead:last-child tr:last-child th:first-child,
  tbody:last-child tr:last-child td:first-child {
    -webkit-border-bottom-left-radius: 4px;
            border-bottom-left-radius: 4px;
        -moz-border-radius-bottomleft: 4px;
  }
  thead:last-child tr:last-child th:last-child,
  tbody:last-child tr:last-child td:last-child {
    -webkit-border-bottom-right-radius: 4px;
            border-bottom-right-radius: 4px;
        -moz-border-radius-bottomright: 4px;
  }
}

// Ordered playlist
.listOrder,
.table-striped tbody > tr > td.listOrder {
    background-color: #eee;
    border-right: 1px solid #ddd;
    cursor: move;

    i {
        display: none;
        margin-left: -2px;
        opacity: 0.5;
    }

    &:hover {
      span {
        display: none;
      }

      i {
          display: inline-block;
      }
    }
}

.disabled .listOrder {
  cursor: not-allowed;
}

tr.active td {
  background-color: rgb(187, 219, 230);
}

.table-hover tbody tr.active:hover > td {
  background-color: #B4DDF1;
}

tbody tr.hover > td, .table-striped tbody tr.hover > td {
    background-color: #B4DDF1;    
}


// Dragging media helper
.mediaDrag {
  position: relative;
  opacity: 0.7;
}

.mediaDrag h1 {
  position: absolute;
  top: 0;
  left: -9px;
  color: #fff;
  text-shadow: 1px -1px 4px #000;
}

.mediaDrag img {
  position: absolute;
}

/* List sorting */

[data-sortby] {
    cursor: pointer;
}

th .caret {
    margin-top: 8px;
}

.caret.caret_top {
    border-bottom: 4px solid #000;
    border-top: none;
}

.caret.caret_right {
    border: 4px solid transparent;
    border-left: 4px solid #555;
    border-right: none;
    margin-top: 6px;
    margin-left: 4px;
}
