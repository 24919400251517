// Flex

.d-col {
  flex-grow: 1;
  // Set min-width to handle canvas issue:
  // https://github.com/chartjs/Chart.js/issues/4156
  min-width: 0;
}

.min-height-1 {
  min-height: 1px;
}

.min-width-1 {
  min-width: 1px;
}

// Scroll
.captureScroll {
  // Smooth scrolling in IOS
  -webkit-overflow-scrolling: touch;

  // Prevent scrolling overflowing parent elements
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  overflow-y: auto;
}

// Dimensions
// DEPRECATED! AVOID USE!

.w10 {
  width: 10px;
}

.w15 {
  width: 15px;
}

.w20 {
  width: 20px;
}

.w30 {
  width: 30px;
}

.w40 {
  width: 40px;
}

.w50 {
  width: 50px;
}

.w60 {
  width: 60px;
}

.w75 {
  width: 75px;
}

.w90 {
  width: 90px;
}

.w110 {
  width: 110px;
}

.w120 {
  width: 110px;
}

.w200 {
  width: 200px;
}

.w250 {
  width: 250px;
}

.w300 {
  width: 300px;
}

.h30,
input.h30 {
  height: 30px;
}

.h100,
.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.mw500 {
  max-width: 500px;
}

.mh-30r {
  max-height: 30rem;
}