.scheme-list {
  .section-header {
    font-weight: bold;
  }

  .playlist.filler {
    background: #e4e4e4;
  }

  .playlist .indented {
    padding-left: 20px;
  }

  .scheme-duration {
    margin-top: -2px;
    padding: 1px 5px;
    width: 55px;
  }

  table.playlist-list {
    border-radius: 3px;
    border: 1px solid #d4d4d4;
    width: 100%;
    border-collapse: collapse;
    background-color: white;
  }

  .playlist-list {
    i {
      opacity: 0.7;
      cursor: pointer;
    }

    td {
      padding: 5px;
    }

    tr:first-child {
      td {
        border-top: none;
      }
    }

    td.handle,
    td:last-child {
      width: 1px;
    }

    tr {
      td {
        border-top: 1px solid #d4d4d4;
      }
    }

    .draggable {
      cursor: pointer;
    }

    .draggable:hover {
      i {
        cursor: pointer;
        opacity: 0.4;
      }
    }

    .is-dragging {
      opacity: 0.2;
      background: #999;
    }
  }

  .area-nav {
    float: none;
    margin-right: 0;
  }

  .nav {
    a {
      cursor: pointer;
    }

    li.disabled a {
      cursor: not-allowed;
    }
  }

  img.layout {
    box-shadow: 1px 1px 3px #383838;
  }
}
