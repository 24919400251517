
// Planning

table.disabled {
    background-color: #dddddd;
    background-image: url("../images/stripe_light.png");
}

.drop_tmpl {
    display: inline-block;
    padding: 0px 5px;
    border-radius: 2px;
    margin-right: 5px;
    border: 1px solid transparent;
    -o-transition: background 0.5s ease;
    -webkit-transition: background 0.5s ease;
    -moz-transition: background 0.5s ease;
    transition: background 0.5s ease;

    &.activate {
        background: #eee;
        border: 1px solid #ddd;
    }

    &.active {
        background: $blue;
        color: #fff;
    }

    &.success {
        background: $green;
        color: #fff;
    }

    &.danger {
        color: $errorText;

        &.activate {
            background: $errorBackground;
            color: $errorText;
        }

        &.active {
            background: $warningBackground;
            color: $warningText;
        }
    }
}

table.disabled .drop_tmpl {
    &.activate,
    &.active,
    &.success {
        background: none;
        color: #555;
    }

    &.danger {
        color: $errorText;

        &.activate {
            background: none;
            color: #555;
        }

        &.active {
        }
    }
}

.olControlScaleBar {
    bottom: 10px;
    left: 10px;
    font-family: sans-serif;
    color: #000;
}

.olControlScaleBarBar {
    height: 1px;
    top: 14px;
    background: #333;
}

.olControlScaleBarBarAlt {
    height: 1px;
    top: 14px;
    background: #333;
}

.olControlScaleBarMarkerMajor {
    height: 7px;
    width: 1px;
    top: 11px;
    background: #333;
}

.olControlScaleBarMarkerMinor {
    height: 5px;
    width: 1px;
    top: 12px;
    background: #333;
}

.olControlScaleBarNumbersBox {
    width: 40px;
    height: 13px;
    top: 21px;
    font-size: 0.7em;
}

.olControlScaleBarLabelBox {
    top: -2px;
    height: 15px;
    font-weight: bold;
    letter-spacing: 0.1em;
    font-size: 0.7em;
    font-variant: small-caps;
}

.olControlScaleBarLabelBoxSingleLine {
    height: 15px;
    width: 35px;
    top: 5px;
    left: 10px;
    font-size: 0.7em;
    font-variant: small-caps;
}

.gs-dropdown .dropdown-menu {
    display: block;
    left: auto;
    right: 0;
}

// Alert in navbar (locked intervalls)
.navbar .alert {
    padding: 6px 20px;
    margin: 0;
    .btn {
        margin-top: 0;
    }
}

#app_header .nav-link i {
  opacity: 0.5;
}

#app_header .nav-link:hover i {
  opacity: 0.8;
}

#app_header .active .nav-link i {
  opacity: 1;
}

/* GS Highligted textarea */

.hltextarea-container {
  width: 100%;
  display: inline-block;
  position: relative;
  overflow: hidden !important;
  background-color: #f8f9fa;
  -webkit-text-size-adjust: none !important;
  box-sizing: border-box;
}

.hltextarea-backdrop {
  position: absolute !important;
  top: 0 !important;
  right: -99px !important;
  bottom: 0 !important;
  left: 0 !important;
  padding-right: 99px !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  box-sizing: border-box;
}

.hltextarea-highlights {
  padding: 5px 10px;
  border: 1px solid #adb5bd;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12pt;
  line-height: 16pt !important;
  background: none transparent !important;
  width: auto !important;
  height: auto !important;
  border-color: transparent !important;
  white-space: pre-wrap !important;
  word-wrap: break-word !important;
  color: transparent !important;
  overflow: hidden !important;
  box-sizing: border-box;
  -webkit-text-size-adjust: none !important;
}

.hltextarea-highlights mark {
  border-radius: 4px;
  padding: 0!important;
  color: black;
  border: none;
  box-sizing: border-box;
  margin-left: 0px;
  margin-right: 0px;
  background-color: $infoBackground;
  border-top: 1px solid $btnInfoBackground;
  border-bottom: 1px solid $btnInfoBackground;
  line-height: 0;
}

.hltextarea-input {
  margin: 0px;
  height: 80px !important;
  width: 100% !important;

  padding: 5px 10px;
  border: 1px solid #adb5bd;
  color: inherit;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12pt;
  line-height: 16pt !important;
  background: none transparent !important;

  display: block !important;
  position: relative !important;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  border-radius: 0;

  box-sizing: border-box;
}

.chart-label {
    display: inline-block;
    max-width: 120px;
    text-overflow: ellipsis;
    overflow: hidden;
    vertical-align: middle;
}


/*
  Resizeable layout boxes
*/

.active .resizeable-handle-bottom:after,
.active .resizeable-handle-bottomLeft:after,
.active .resizeable-handle-bottomRight:after,
.active .resizeable-handle-left:after,
.active .resizeable-handle-right:after,
.active .resizeable-handle-top:after,
.active .resizeable-handle-topLeft:after,
.active .resizeable-handle-topRight:after {
  content: '';
  position: absolute;
  background-color: #000;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  top: 50%;
  left: 50%;
}

.resizeable-handle-bottom {
  transform: translateY(-3px);
}
.resizeable-handle-bottomLeft {
  transform: translate(-5px, -3px);
}
.resizeable-handle-bottomRight {
  transform: translate(-3px, -3px);
}
.resizeable-handle-left {
  transform: translateX(-5px);
}
.resizeable-handle-right {
  transform: translateX(-3px);
}
.resizeable-handle-top {
  transform: translateY(-5px);
}
.resizeable-handle-topLeft {
  transform: translate(-5px, -5px);
}
.resizeable-handle-topRight {
  transform: translate(-3px, -5px);
}
