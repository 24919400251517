$infoBackground: #d9edf7;
$errorBackground: #f2dede;
$tableBorder: ddd; // table and cell border
$errorText: #b94a48;
$warningBackground: #fcf8e3;
$warningText: #c09853;
$btnInfoBackground: #5bc0de;
$white: #fff;
$green: #468847;

$gray-200: #e9ecef !default;

$link-color: #08c;

$theme-colors: (
  "dark": #000,
  "info-light": #B4DDF1,
);

$enable-shadows: true;
$enable-gradients: false;

$font-family-sans-serif: 'Roboto', Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-weight: 700;
$input-btn-focus-width: .1rem;
$dropdown-link-hover-bg: $gray-200;
