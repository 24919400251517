.rw-popup-right-align {
	.rw-popup-container {
		right: -1em;
		left: auto;
	}
}

.rw-select-only {
	.rw-select {
		position: relative;
		height: 100%;
		border: none;
	}

	.rw-widget-picker.rw-widget-container {
		background-color: transparent;
		border: none;
	}

	.rw-datetime-picker,
	.rw-combobox {
		width: 20px;
		border: none;

		input {
			height: 32px;
			padding: 0 10px;
			margin-bottom: 0px;
		}

		button {
			line-height: 1;
		}

		.rw-input {
			display: none !important;
		}
	}
}

.rw-list {
	li.rw-list-option.rw-state-focus {
		background-color: #0081c2;
		color: white;

	}

	li.rw-list-option:hover {
		background-color: white;
		color: black;
	}

	.row-content {
		padding: 2px 10px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	:not(.rw-state-focus) {
		.row-content.warning {
			background: #FCF8E3;
			border-radius: 5px;
		}
	}

}

.rw-list>li.rw-list-option {
	padding: 0;
}